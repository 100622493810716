// components/RequestAccountDeleteModal.js

import React, { useState } from 'react';
import Modal from 'react-modal';
import emailjs from '@emailjs/browser';

Modal.setAppElement('#root');

const customStyles = {
    content: {
        background: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
        backdropFilter: 'blur(10px)', // Blur effect
        WebkitBackdropFilter: 'blur(10px)', // Safari support
        border: '0px solid rgba(255, 255, 255, 0.3)', // Subtle border
        borderRadius: '10px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '40px',
        width: '550px',
        textAlign: 'center',
        color: 'white', // White text for contrast
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Dark semi-transparent background for overlay
    },
};

const RequestAccountDeleteModal = ({
    isOpen,
    onRequestClose,
    setIsModalOpen,
}) => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.init({
            publicKey: '069qc1LM4XT-YNsr8',
        });

        const serviceId = 'service_xf3sc7w';
        const templateId = 'template_q27ippa';

        const templateParams = {
            message: email,
        };

        emailjs.send(serviceId, templateId, templateParams).then(
            function (response) {
                console.log('SUCCESS!', response.status, response.text);
                alert('Success!');
                setEmail('');
                setIsModalOpen(false);
            },
            function (err) {
                console.log('FAILED...', err);
                setIsModalOpen(false);
            }
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Request Account Deletion"
            style={customStyles}
        >
            <h3>Request Account Deletion?</h3>
            <br />
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">
                    To initiate the process of deleting your account, please
                    provide us with your email address. The account deletion
                    process will take approximately six business days to
                    complete.
                </label>
                <br />
                <br />
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                    style={{
                        width: '100%',
                        padding: '8px',
                        margin: '10px 0',
                        borderRadius: '5px',
                        color: 'black',
                    }}
                />
                <button
                    type="submit"
                    style={{
                        backgroundColor: '#1e90ff',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Submit
                </button>
                <button
                    type="button"
                    onClick={onRequestClose}
                    style={{
                        backgroundColor: '#ff6347',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginLeft: '10px',
                    }}
                >
                    Cancel
                </button>
            </form>
        </Modal>
    );
};

export default RequestAccountDeleteModal;
