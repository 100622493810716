import React, { useState, useEffect, useRef } from 'react';
import './stylesss.css';
import logo from './logooo.png';
import Dotted_Logo from './Dotted_Logooo.png';
import GooglePlayButton from './googleplayfinalll.png';

const Home2 = () => {
    const [email, setemail] = useState('');

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video
                .play()
                .catch((error) => console.error('Autoplay blocked:', error));
        }
    }, []);

    const sendData = async (formData) => {
        try {
            const response = await fetch(
                `https://api.engine.workverse.in/api/v2/users/emails/${email}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                }
            );

            if (!response.ok) {
                alert('Error Submitting');
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            alert('Submitted, Thank you!');
            setemail('');
            console.log('Form data submitted successfully:', responseData);
        } catch (error) {
            alert('Error Submiting');
            console.error('Error submitting form data:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            email,
        };
        sendData(formData);
    };

    return (
        <>
            {/* <Header /> */}
            <form
                onSubmit={handleSubmit}
                className="containerrr"
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <img src={Dotted_Logo} alt="Overlay GIF" className="rotate" />
                <div className="form-container">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <img src={logo} alt="logo" width="200px" />
                        <a
                            href="https://workverse.in/privacy-policy"
                            className="hideOnPhone"
                            style={{ color: '#fff' }}
                        >
                            Privacy Policy
                        </a>
                    </div>
                    <video
                        ref={videoRef}
                        width="640"
                        height="360"
                        playsinline
                        controls
                        autoplay
                        muted
                        loop
                        className="video"
                        src="https://workverse-emails.s3.us-east-1.amazonaws.com/video.mp4"
                    />
                    {/* <video
                        width="640"
                        height="360"
                        playsinline
                        controls
                        autoplay
                        loop
                        class="video"
                        src=""
                    /> */}

                    <p
                        className="title"
                        style={{
                            fontSize: '45px', // Reduced font size for better readability
                            textAlign: 'center',
                            color: '#fff',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            marginBottom: '3vh', // Adjusted to be responsive based on viewport height
                            marginTop: '4rem', // Adjusted top margin for better spacing
                            lineHeight: '1.2', // Added to improve readability
                        }}
                    >
                        Workverse Is Being Manifested
                    </p>
                    <p
                        className="sub-title"
                        style={{
                            fontSize: '18px',
                            textAlign: 'center',
                            color: '#808080',
                            fontStyle: 'normal',
                            marginTop: '0px',
                            marginBottom: '30px',
                            fontWeight: '200',
                        }}
                    >
                        Share your email ID and we will reach out when it's
                        ready to produce automation proof talent for Bharat.
                    </p>

                    <div
                        className="emailbox"
                        style={{
                            marginBottom: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <div className="border-container">
                            <input
                                type="text"
                                value={email}
                                placeholder="Email"
                                className="border"
                                onChange={(e) => setemail(e.target.value)}
                            />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="submit-form" style={{}}>
                                <button type="submit" className="border-btn">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <hr style={{ width: '40px' }} />
                    </div>

                    <p
                        style={{
                            fontSize: '18px',
                            textAlign: 'center',
                            color: '#808080',
                            fontStyle: 'normal',
                            fontWeight: '200',
                            marginTop: '20px',
                        }}
                    >
                        Download the app here
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={GooglePlayButton}
                            alt="Google Play Store"
                            width="200px"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                window.open(
                                    'https://play.google.com/store/apps/details?id=com.Workverse.Simulator&pcampaignid=web_share',
                                    '_blank'
                                )
                            }
                        />
                    </div>

                    <div
                        className="privacy"
                        style={{
                            display: 'none',
                            justifyContent: 'end',
                            paddingTop: '30px',
                        }}
                    >
                        <a
                            href="https://workverse.in/privacy-policy"
                            style={{ color: '#fff' }}
                        >
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Home2;
