import React, { useState, useEffect } from 'react';
import RequestAccountDeleteModal from '../components/RequestAccountDeleteModal';

function PrivacyPolicy() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Check if the URL contains the anchor link for requestDeletion
        if (window.location.hash === '#requestDeletion') {
            setIsModalOpen(true); // Open the modal
        }
    }, []);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="w-full gradient overflow-x-hidden">
            <div className="w-full mx-auto container mt-4">
                <div className="md:col-span-3 ">
                    <h1 className="text-[#bafff7] text-center font-bold my-2">
                        Privacy Policy
                    </h1>
                    <h4 className="text-white text-center font-light">
                        Last Updated: 26th June 2024
                    </h4>
                    <br />
                    <h4 className="text-white text-center font-light">
                        At Workverse, we are committed to protecting the privacy
                        of our visitors and Learners. This Privacy Policy
                        outlines the types of information we collect and how we
                        use it.
                    </h4>
                    <br />
                    <p>
                        <strong>Consent</strong>
                    </p>
                    <h4>
                        By using our website and the mobile app, you hereby
                        consent to our Privacy Policy and agree to its terms.
                    </h4>
                    <br />
                    <p>
                        <strong>Information We Collect</strong>
                    </p>
                    <h4>
                        We collect personal information when you:
                        <br />
                        &nbsp;&nbsp; - Contact us directly: This may include
                        your name, email address, phone number, and any other
                        information you choose to provide.
                        <br />
                        &nbsp;&nbsp; - Register for an account: This may include
                        your contact information, such as name, company name,
                        college name, address, email address, and telephone
                        number.
                        <br />
                        &nbsp;&nbsp; - Communicate with your support manager and
                        the AI model (Neuroda): We may collect information
                        related to these communications.
                        <br />
                        We also collect non-personal information such as IP
                        addresses, browser types, Internet Service Provider
                        (ISP), date and time stamps, referring/exit pages, and
                        click data.
                    </h4>
                    <br />
                    <p>
                        <strong>How We Use Your Information</strong>
                    </p>
                    <h4>
                        We use the information we collect in various ways,
                        including:
                        <br />
                        &nbsp;&nbsp; - Improve, personalize, and expand our
                        game.
                        <br />
                        &nbsp;&nbsp; - Understand and analyze your progress in
                        the learning process.
                        <br /> &nbsp;&nbsp; - Develop new products, services,
                        features, and functionality.
                        <br /> &nbsp;&nbsp; - Communicate with you, either
                        directly or through one of our partners, for customer
                        service, updates, marketing, and promotional purposes.{' '}
                        <br />
                        &nbsp;&nbsp; - Send you emails.
                        <br /> &nbsp;&nbsp; - Prevent and detect fraud.
                    </h4>
                    <br />
                    <p>
                        <strong>Log Files</strong>
                    </p>
                    <h4>
                        We use log files to track visitors when they visit our
                        website or use our services. These files log IP
                        addresses, browser types, ISPs, date and time stamps,
                        referring/exit pages, and click data. This information
                        is not linked to any personally identifiable
                        information.
                    </h4>
                    <br />
                    <p>
                        <strong>Cookies, Cache, storage and Web Beacons</strong>
                    </p>
                    <h4>
                        We use "cookies" and local “cache” to store information,
                        including visitors' preferences and the pages on our
                        website or game that visitors accessed, played, or
                        visited. This information is used to optimize the user's
                        experience by customizing our web page content based on
                        the visitor's browser type and other information, or
                        based on the game the player is engaged with.
                    </h4>
                    <br />
                    <p>
                        <strong>
                            CCPA Privacy Rights (Do Not Sell My Personal
                            Information)
                        </strong>
                    </p>
                    <h4>
                        Under the CCPA, among other rights, Indian consumers
                        have the right to: <br /> &nbsp;&nbsp;- Request that a
                        business that collects a consumer's data disclose the
                        categories and specific pieces of personal data that a
                        business has collected about consumers. <br />
                        &nbsp;&nbsp;- Request that a business delete any
                        personal data about the consumer that a business has
                        collected.
                        <br /> &nbsp;&nbsp;- Request that a business that sells
                        a consumer's data, not sell the consumer's data. If you
                        make a request, we have one month to respond to you. If
                        you would like to exercise any of these rights, please
                        contact us.
                    </h4>
                    <br />
                    <p>
                        <strong>GDPR Data Protection Rights</strong>
                    </p>
                    <h4>
                        We would like to make sure you are fully aware of all of
                        your data protection rights. Every user is entitled to
                        the following: &nbsp;&nbsp;- The right to access - You
                        have the right to request copies of your personal data.
                        We may charge you a small fee for this service.
                        <br /> &nbsp;&nbsp;- The right to rectification - You
                        have the right to request that we correct any
                        information you believe is inaccurate. You also have the
                        right to request that we complete the information you
                        believe is incomplete.
                        <br /> &nbsp;&nbsp;- The right to erasure - You have the
                        right to request that we erase your personal data, under
                        certain conditions.
                        <br /> &nbsp;&nbsp;- The right to restrict processing -
                        You have the right to request that we restrict the
                        processing of your personal data, under certain
                        conditions.
                        <br />
                        &nbsp;&nbsp;- The right to object to processing - You
                        have the right to object to our processing of your
                        personal data, under certain conditions.
                        <br /> &nbsp;&nbsp;- The right to data portability - You
                        have the right to request that we transfer the data that
                        we have collected to another organization, or directly
                        to you, under certain conditions. If you make a request,
                        we have one month to respond to you. If you would like
                        to exercise any of these rights, please contact us.
                    </h4>
                    <br />
                    <p>
                        <strong>Children's Information</strong>
                    </p>
                    <h4>
                        We do not knowingly collect any Personally Identifiable
                        Information from children under the age of 13. If you
                        believe your child has provided such information on our
                        website, please contact us immediately, and we will do
                        our best to promptly remove such information from our
                        records.
                    </h4>
                    <br />
                    <p>
                        <strong>Changes to This Privacy Policy</strong>
                    </p>
                    <h4>
                        We may update our Privacy Policy from time to time.
                        Thus, we advise you to review this page periodically for
                        any changes. We will notify you of any changes by
                        posting the new Privacy Policy on this page. These
                        changes are effective immediately after they are posted
                        on this page.
                    </h4>
                    <br />
                    <p>
                        <strong>Data and Account Deletion</strong>
                    </p>
                    <h4>
                        If you wish to delete your data or account, please
                        contact us at dev@workverse.in. Upon receiving your
                        request, we will delete your personal data and account
                        information from our records within a reasonable
                        timeframe, unless we are required to retain it for
                        legal, regulatory, or security purposes.Please note that
                        once your data or account is deleted, it cannot be
                        recovered. Any remaining anonymized information that
                        does not identify you personally may still be retained
                        and used for analytics or other lawful purposes. If you
                        have any questions or concerns about our Privacy Policy
                        or the data and account deletion process, please contact
                        us at dev@workverse.in
                    </h4>
                    <br />
                    <p>
                        <strong>Contact Us</strong>
                    </p>
                    <h4>
                        If you have any questions or suggestions about our
                        Privacy Policy, do not hesitate to contact us at:
                        <br /> &nbsp;&nbsp;- Phone: +91 9975008124 <br />{' '}
                        &nbsp;&nbsp;- Email: dev@workverse.in <br /> Thank you
                        for trusting Workverse with your information. We are
                        dedicated to maintaining the privacy and security of
                        your data.
                    </h4>
                    <br />
                    <br />
                    <br />
                    <button
                        onClick={openModal}
                        className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
                    >
                        Request Account Deletion
                    </button>
                    <RequestAccountDeleteModal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        setIsModalOpen={setIsModalOpen}
                    />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
